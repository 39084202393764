import React from 'react'
import { graphql } from 'gatsby'
import { extract, handlePrismicLinkField } from '../utils/graphql'
import { localizePath } from '../../config/i18n'

// components
import Founder from '../components/Templates/Founder'
import NoTranslation from '../components/Templates/NoTranslation'

// utils
import { withPreview } from 'gatsby-source-prismic'

const getFounderInfo = (page) => {
  const i = page.data
  return {
    name: i.founders_name.text,
    imgSrc: i.founders_image.url,
    bio: i.bio.html,
  }
}

const getArticles = (page) => {
  const articles = page.data.articles
  return articles.map((article) => {
    return {
      highlightText: article.highlight_text.text,
      title: article.article_title.text,
      img: {
        src: article.image.url,
        alt: article.image.alt,
      },
      button: {
        text: article.article_link_text.text,
        ...handlePrismicLinkField(article.article_link_url),
      },
    }
  })
}

const FounderPage = ({ data, pageContext }) => {
  const { prismicOurFounder } = data
  const page = prismicOurFounder
  if (!page) return <NoTranslation></NoTranslation>

  const hydratedData = {
    lang: pageContext.locale,
    seo: extract.seo(page),
    mantelData: extract.mantel(page, {
      parent: {
        path: localizePath(pageContext.locale, `/who-we-are`),
        text: 'Who we are',
      },
    }),
    founderInfo: getFounderInfo(page),
    articles: getArticles(page),
  }
  return <Founder {...hydratedData} />
}

export default withPreview(FounderPage)

export const pageQuery = graphql`
  query FounderBySlug($locale: String!) {
    prismicOurFounder(lang: { eq: $locale }) {
      data {
        title {
          text
        }
        seo_keywords {
          keyword {
            text
          }
        }
        seo_description {
          text
        }
        mantel_title {
          text
        }
        founders_name {
          text
        }
        founders_image {
          url
          alt
        }
        bio {
          html
        }
        articles {
          image {
            url
            alt
          }
          highlight_text {
            text
          }
          article_title {
            text
          }
          article_link_text {
            text
          }
          article_link_url {
            url
            link_type
            uid
            document {
              __typename
              ... on PrismicArticle {
                data {
                  article_type
                }
              }
            }
          }
        }
      }
    }
  }
`
