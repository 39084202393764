import React from 'react'

import PageWrap from '../../Layouts/Global/PageWrap'
import WysiwygContent from '../../General/WysiwygContent'

const content = '<h4>Translation unavailable for this lang</h4>'

const NoTranslation = () => (
  <PageWrap>
    <div className="max-w-xl mx-auto py-8">
      <WysiwygContent content={ content } />
    </div>
  </PageWrap>
)

export default NoTranslation
