import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import tw from 'tailwind.macro'
import { screen } from '../../../utils/index'
import TextButton from '../Buttons/TextButton'
import Button from '../Buttons/Button'

const toutWrapClasses = `
  lg:flex
`

const highlightTextClasses = `
  font-sans
  text-sm
  tracking-wide
  uppercase
  text-green-3
  mb-2
  font-bold
`

const toutHeaderClasses = `
  font-sans
  font-bold
  text-2xl
  mb-2
  leading-none
  text-charcoal
  hover:underline
  hover:text-green-3
`

const textClasses = `
  font-sans
  text-base
  font-light
  text-dark-grey-2
  leading-normal
`

const ToutSmall = ({ title, highlightText, text, img, button }) => {
  const LinkComponent = button.external ? 'a' : Link
  const ButtonComponent = button.withBorders ? Button : TextButton

  const ToutImage = styled(LinkComponent)`
    ${tw`
      mb-2
      w-full
      lg:w-1/2
      sm:flex-no-shrink
      lg:mr-2
      lg:mb-0
      block
    `}

    ${screen('lg')} {
      max-width: 200px;
    }
  `
  return (
    <div className={toutWrapClasses}>
      <ToutImage
        className=""
        to={button.route}
        href={button.route}
        target={button.external ? '_blank' : null}
      >
        <img src={img.src} alt={img.alt} className="block w-full" />
      </ToutImage>
      <div>
        {highlightText && (
          <div className={highlightTextClasses}>{highlightText}</div>
        )}
        <LinkComponent
          className="no-underline mb-2"
          to={button.route}
          href={button.route}
          target={button.external ? '_blank' : null}
        >
          <h1 className={toutHeaderClasses}>{title}</h1>
        </LinkComponent>
        <div className="mb-2">
          <p className={textClasses}>{text}</p>
        </div>
        <LinkComponent
          className="no-underline"
          to={button.route}
          href={button.route}
          target={button.external ? '_blank' : null}
        >
          <ButtonComponent>{button.text}</ButtonComponent>
        </LinkComponent>
      </div>
    </div>
  )
}

export default ToutSmall
