// component data
// backgrounds

const pageBgs = {
  technology: 2,
  resources: 1,
  ecosystem: 3,
  about: 2,
  'our-team': 1,
  contact: 3,
}

export const getPageBgs = (id) => {
  return pageBgs[id]
}

export const handlePrismicLinkField = (link) => {
  // in order to use this make sure that the link field has the following fields
  // url
  // link_type
  // uid
  // document {
  //   __typename
  //   ... on PrismicArticle {
  //     data {
  //       article_type
  //     }
  //   }
  // }

  let format = {
    route: '',
    external: false,
    url: '',
  }
  if (link.document) {
    if (link.document.__typename === 'PrismicArticle') {
      let path = `/resources/${
        link.document.data.article_type === 'blog' ? 'blog' : 'news'
      }/${link.uid}`
      format.url = path
      format.route = path
    }
    if (link.document.__typename === 'PrismicEconomicInnovationArticle') {
      let path = `/technology/economic-innovation/${link.uid}`
      format.url = path
      format.route = path
    }
    if (link.document.__typename === 'PrismicTechnologySubPage') {
      let path = `/technology/technology/${link.uid}`
      format.url = path
      format.route = path
    }
  }
  if (link.link_type === 'Web') {
    format.route = link.url
    format.url = link.url
    format.external = true
  }
  if (link.link_type === 'Media') {
    format.route = link.url
    format.url = link.url
    format.external = true
  }
  return format
}

export const extract = {
  seo: (query) => {
    return {
      title: query.data.title.text,
      description: query.data.seo_description.text,
      keywords: query.data.seo_keywords.map((word) => word.keyword.text),
    }
  },
  mantel: (query, { isThick = false, backgrounds = null, parent = null }) => {
    const crumbs = parent
      ? [parent, { text: query.data.mantel_title.text }]
      : null
    return {
      thiccHeading: isThick,
      title: query.data.mantel_title.text,
      description: query.data.mantel_content
        ? query.data.mantel_content.html
        : null,
      backgrounds,
      crumbs,
    }
  },
  subnav: (query) => {
    return query.data.sub_pages.map((page) => ({
      text: page.link_text.text,
      route: page.page.url,
    }))
  },
  tout: (query) => {
    return {
      title: query.data.tout_title.text,
      html: query.data.tout_content.html,
      button: {
        text: query.data.tout_button_text.text,
        ...handlePrismicLinkField(query.data.tout_button_url),
      },
      img: {
        src: query.data.tout_image.url,
        alt: query.data.tout_image.alt,
      },
      isVideo: query.data.link_is_a_video === 'Yes',
    }
  },
  callToAction: (query) => {
    return {
      heading: query.data.call_to_action_title.text,
      text: query.data.call_to_action_content.text,
      button: {
        route: query.data.call_to_action_button_url.url, // TODO: replace with link
        text: query.data.call_to_action_button_text.text,
      },
    }
  },
}
