import React from 'react'
import classNames from 'classnames'
import PageWrap from '../../Layouts/Global/PageWrap'
import TwoUp from '../../Layouts/TwoUp'
import Mantel from '../../General/Mantel'
import ToutSmall from '../../General/ToutSmall'
import WysiwygContent from '../../General/WysiwygContent'
import SecondaryHeading from '../../General/Headings/SecondaryHeading'
import { Link } from 'gatsby'
import TextButton from '../../General/Buttons/TextButton'

const Founder = ({ seo, mantelData, founderInfo, articles }) => (
  <PageWrap bg={2} seo={seo}>
    <Mantel {...mantelData} />
    <div className="mx-auto max-w-3xl my-4">
      <div className="mb-2">
        <SecondaryHeading>{founderInfo.name}</SecondaryHeading>
      </div>
      <TwoUp>
        <div>
          <img className="w-full mb-2" src={founderInfo.imgSrc} />
          <WysiwygContent content={founderInfo.bio} />
        </div>
        <div>
          {articles &&
            articles.map((article, i) => (
              <article
                key={`founder-article-${i}`}
                className={classNames({
                  'mb-5': articles.length > i + 1,
                })}
              >
                <ToutSmall {...article} />
              </article>
            ))}
          <div className="mt-5">
            <Link to="/publications" className="no-underline">
              <TextButton>View More Blogs</TextButton>
            </Link>
          </div>
        </div>
      </TwoUp>
    </div>
  </PageWrap>
)

export default Founder
