import React from 'react'
import classNames from 'classnames'
import RightChevron from '../../Shapes/RightChevron'
import styled from 'styled-components'

const ButtonText = styled.div``

const Button = ({
  children,
  whiteText,
  magentaText = false,
  isPrimary = false,
}) => {
  const wrapClasses = `
    flex
    items-baseline
    cursor-pointer
  `

  const baseTextClasses = `
    mr-1
    font-sans
    leading-none
    text-sm
    tracking-wide
  `

  return (
    <button
      className={classNames(wrapClasses, {
        'text-white': whiteText,
        'text-charcoal hover:text-green-3': !whiteText,
        'text-green-3 hover:underline': magentaText,
      })}
    >
      <ButtonText
        className={classNames(baseTextClasses, {
          'text-sm': !isPrimary,
        })}
      >
        {children}
      </ButtonText>
      <RightChevron className="flex" />
    </button>
  )
}

export default Button
