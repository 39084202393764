import React from 'react'
import classNames from 'classnames'

const baseHeadingClasses = `
  font-sans
  font-bold
  text-2xl
`

const SecondaryHeading = ({ children, whiteText, ...rest }) => (
  <h2
    className={classNames(baseHeadingClasses, {
      'text-white': whiteText,
      'text-charcoal': !whiteText,
    })}
    {...rest}
  >
    {children}
  </h2>
)

export default SecondaryHeading
